









































































import {Vue, Component} from "vue-property-decorator";
import {ToolResource} from "@/request/store/ToolResource";
import {Utils} from "@/common/Utils";

@Component({
    name: 'store_tool_resource'
})
export default class store_tool_resource extends Vue {
    loading: boolean = false;
    showTable: boolean = true;
    modal: any = ToolResource;
    search: Array<any> = [
        {
            type: 'Input',
            label: '文件名',
            key: 'name',
            width: '160px',
            clearable: true,
            placeholder: '请填写文件名'
        }
    ];
    detailModal: any = {
        show: false,
        row: {
            name: '',
            resType: '',
            url: '',
            size: 0
        }
    };
    previewWidth: number = 120;
    previewHeight: number = 80;
    params: any = {
        pageIndex: 1,
        pageSize: 10
    };
    resData: Array<any> = [];
    columns: Array<any> = [
        {
            title: '文件名',
            key: 'name',
            minWidth: 200,
            tooltip: true
        },
        {
            title: '文件类型',
            key: 'resType',
            minWidth: 120
        },
        {
            title: '文件大小',
            key: 'size',
            minWidth: 120,
            render: (h: any, p: any) => h('p', Utils.covertFileSize(p.row.size))
        },
        {
            title: '上传时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {
        this.getData();
    }
    getData() {
        this.loading = true;
        ToolResource.list(this.params).then((body: any) => {
            this.loading = false;
            this.resData = body.data;
        })
    }
    detail(row: any) {
        this.detailModal.show = true;
        this.detailModal.row = row;
        this.detailModal.row.coverSize = Utils.covertFileSize(row.size);
    }
    handleView(item: any) {
        let self: any = this;
        if (item.resType.indexOf('image') !== -1) {
            self.$refs['image-preview'].show(item.url);
        } else if (item.resType.indexOf('video') !== -1) {
            self.$refs['video-preview'].show(item.url);
        }
    }
    handleRemove(item: any) {
        ToolResource.del([item.id]).then((body: any) => {
            if (body.code === 0) {
                this.getData();
            }
        })
    }
    pageSizeChange(pageSize: number) {
        this.params['pageSize'] = pageSize;
        this.getData();
    }
    changePage(pageIndex: number) {
        this.params['pageIndex'] = pageIndex;
        this.getData();
    }
    doCopy() {
        this.$copyText(this.detailModal.row.url).then(() => {
            this.$Message.info('复制成功');
        })
    }
}
